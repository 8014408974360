<template>
  <!--<NewNav v-if="!this.hideNav" />-->
  <NewNewNav v-if="!this.hideNav" /> 
  <router-view/>
  <FooterComponent v-if="!this.hideNav" />

</template>

<script>
//import NavComponent from './components/NavComponent.vue';
//import NewNav from './components/NewNav.vue';
import NewNewNav from './components/NewNewNav.vue';
import FooterComponent from './components/FooterComponent.vue';

export default {
    components: {
      //NavComponent,
      //NewNav,
      NewNewNav,
      FooterComponent
    },

    props: {

    },

    watch: {
      $route(routePath) {
        if(routePath.name === "comingsoon") {
          this.hideNav = true;
        } else {
          this.hideNav = false;
        }
      }
    },

    data() {
        return {
          hideNav: false
        }
    },

    beforeMount() {
      //this.$router.push("/comingsoon")
    },

    mounted() {

    },

    computed: {

    },

    methods: {

    },
}
</script>


<style>
/*
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
*/

* {
    font-family: 'Cormorant Garamond', serif;
    cursor: url(./assets/images/heart.svg) 10 3, auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
  }
  
  html {
    background-color: #fff;
    height: 100%;
    line-height: 1.5;
    overflow-x: hidden;
    overflow-y: scroll;
    box-sizing: border-box;
    color: #030104;
  }
  
  html, body {
    font-family: "Lato", sans-serif;
    font-size: 16px;
    min-height: 100%;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
  }

  a {
    cursor:default;
  }

  /* general pages */
  
  .section{
    min-height: 100vh;
    height: auto;
    padding-bottom: 20px;
  }

  .flex-container {
    display: flex;
    flex-wrap: wrap;
  }
  
  .card {
    flex: 1;
  }
  
  img {
    max-width: 100%;
  }
  
  h1, h2, h3, h4 {
    /*font-family: 'Josefin Sans', sans-serif;*/
    letter-spacing: 1.5px;
    /*color: #030104;*/
    color: #2b3425;
  }
  
  h1 {
    line-height: 1;
    margin-top: 0;
    text-align: left;
  }
  
  .contain {
    margin: 0 10%;
    height: fit-content;
  }
  
  .hero-text {
    width: 80%;
    z-index: 1;
    text-align: left;
  }

  .hero-title {
    font-family: 'Cormorant Garamond', serif;
    font-weight: 900;
    text-align: left;
    font-size: 54px;
  }
  
  .cat{
    font-size: 28px;
    letter-spacing: 2px;
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    color: #030104;
    margin-top: 30px;
    z-index: 1;
  }
  
  .cat:after {
    position: absolute;
    top: 50%;
    left: 100%;
    content: "";
    width: 50px;
    height: 1px;
    background-color: #2b3425;
    margin-left: 5px;
    z-index: 1;
  }
  
  .cat.margin-bottom{
    margin-bottom: 1rem;
  }
  
  .description {
    letter-spacing: 1px;
    width: 100%;
    float: left;
    margin-bottom: 1rem;
    color: #030104;
    display: contents;
  }
  
  .description p {
    font-size: 1.2rem;
    margin-bottom: 15px;
  }
  
  #bg{
    z-index: -1;
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
  }
  
  #bg img {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -40%);
    max-width: 750px;
    max-height: 80vh;
  }
  
  
  @media only screen and (min-width: 1280px) {
    .description {
      width: 50%;
    }
    .hero-title {
      font-size: 128px;
    }
  }

  
  @media only screen and (max-width: 600px) {
    .cat:after {
      display: hidden;
    }

  }

  
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  /*background: #f1f1f1;*/
  background: rgba(0, 0, 0, 0.0);
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #415B41; 
}


/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000; 
}

::selection {
  color: #000;
  background-color: #415B41;
}
  </style>
<template>
  <div id="fullpage" class="home pt-40">

    <div class="section" id="section1">
        <div class="contain">
            <div class="hero-text">
                <div class="hero-title">
                    <!--<h1>Jo Babe<br/>Celebrant</h1>-->
                    <img src="../assets/JB.png" alt="" style="border-radius: 100%;">
                </div>
                <div>
                    <span class="cat">CONGRATULATIONS and WELCOME</span>
                </div>
            </div>
        </div>
    </div>

    <div class="section" id="section2">
      <span class="cat">About Me</span>
      <div class="contain">
          <div class="description">
            <!--
            <p class="bold">Hi,</p>
            <p class="bold" style="margin-left: 50px;">I'm Jo</p>
            <p class="text">
              and I am excited that you are about to commence on your marriage journey. Being included in your team to help make your marriage official would be my honour.
            </p>
            <p class="text">
              Becoming an Independent Registered Marriage Celebrant is a highlight and one that I am passionate about. I am a member of Celebrants' Association of New Zealand and having trained with The Celebrant School of New Zealand has taught me the importance of crafting a bespoke ceremeony to reflect your love story and your personalities.
              Creating beautiful memories takes understanding, warmth and reassurance blended with fun and laughs along the way. I'd love to meet and get to know you, as each couple are as unique and special as you deserve your wedding day to be.
              I am based in Canterbury but travelling to your chosen wedding venue is something I am happy to accommodate.
            </p>
            <p class="text">
              I can't wait to meet you, walk side by side with you by offering guidance though your ceremony journey and to finally pronounce you married!
            </p>
          -->
          <p class="bold">Hi everyone,</p>
            <!--<p class="bold" style="margin-left: 50px;">I'm Jo</p>-->
            <p class="text">
              I’m Jo, your warm hearted, genuine and fun marriage celebrant. Being included in your team to help make your marriage ceremony unique and loving as well as official would be my honour.  
            </p>
            <p class="text">
              I am very passionate about my role as an independent marriage celebrant! I obtained my qualification through The Celebrant School of New Zealand and I am a member of Celebrants&#39; Association of New Zealand.
            </p>
            <p class="text">
              Crafting a bespoke ceremony together, to reflect your love story and your personalities will be at the heart of your wedding day. Creating beautiful memories takes understanding, warmth and reassurance blended with fun and laughs along the way. Guiding your ceremony journey so that is it the perfect reflection of your relationship is my main focus. Making genuine connections to bring a warm, friendly vibe to your ceremony and understand what you want, really adds direction and ease to the process.
            </p>
            <p class="text">
              I believe that each couple are as unique and special as you deserve your marriage ceremony to be.
            </p>
            <p class="text">
              Based in Selwyn, Canterbury, I am happy to travel to your chosen wedding venue. Whether you want big or small, casual or formal, reach out to me. I’d be delighted to help compose the perfect ceremony for you.
            </p>
          </div>
      </div>
    </div>
    
    <div id="bg">
      <img src="../assets/images/kindpng_479637.png" alt="">
    </div>

  </div>

</template>

<script>
import * as ScrollMagic from 'scrollmagic'; // Or use scrollmagic-with-ssr to avoid server rendering problems
import { TweenMax, TimelineMax } from 'gsap'; // Also works with TweenLite and TimelineLite
import { ScrollMagicPluginGsap } from 'scrollmagic-plugin-gsap';


ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);

export default {
  name: 'HomeView',

  components: {
    
  },

  props: {

  },

  watch: {
    $route(routePath) {
      if(routePath.name === "comingsoon") {
        this.hideNav = true;
      } else {
        this.hideNav = false;
      }
    }
  },

  data() {
      return {
        hideNav: false
      }
  },

  beforeMount() {
    
  },

  mounted() {
    document.title = "Home | Jo Babe Celebrant"


    const controller = new ScrollMagic.Controller()

    let scene = new ScrollMagic.Scene({
        offset: 100,
        duration: 500
    })
        .setTween('#bg img', {
            maxWidth: '1200px',
            top: '40%',
            left: '80%',
            opacity: 0.25
        }) 
        .addTo(controller)

    scene = new ScrollMagic.Scene({
      offset: 100,
      duration: 300
    })

        .setTween('#bg img', {
            top: '40%'
        })
        .addTo(controller)

    scene = null;
    console.log(scene)

  },

  computed: {

  },

  methods: {

  },
}
</script>

<style scoped>
.bold {
  font-weight: 800;
  font-size: large;
  text-align: left;
}

.text {
 font-size: large;
}

@media only screen and (min-width: 600px) {
    .bold {
      font-size: x-large;
    }
    .text {
      font-size: x-large;
    }

  }

</style>
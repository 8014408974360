<template>
    <nav class="w-full h-16 drop-shadow-lg navbar bg-white p-4 flex justify-between items-center">
      <router-link to="/" class="nav-link ml-6 text-lg font-semibold">Home</router-link>
  
      <div class="mr-6 hidden md:flex space-x-4">
        <router-link to="/testimonials" class="nav-link">Testimonials</router-link>
        <router-link to="/gallery" class="nav-link">Gallery</router-link>
        <router-link to="/contact" class="nav-link">Contact</router-link>
      </div>
  
      <div class="md:hidden">
        <button @click="toggleMobileMenu">
          <img v-if="!isMobileMenuOpen" src="../assets/menu.svg" alt="">
          <img v-else src="../assets/x.svg" alt="" class="text-right text-4xl">
        </button>
      </div>
  
      <div v-if="isMobileMenuOpen" @click="this.isMobileMenuOpen=false" class="md:hidden absolute top-0 left-0 pt-6 w-full h-screen bg-white flex flex-col items-center">

        <router-link to="/" class="nav-link text-lg font-semibold">Home</router-link>
        <router-link to="/testimonials" class="nav-link my-4">Testimonials</router-link>
        <router-link to="/gallery" class="nav-link my-4">Gallery</router-link>
        <router-link to="/contact" class="nav-link my-4">Contact</router-link>
      </div>
    </nav>
  </template>
  
  <script>
  export default {
    data() {
      return {
        isMobileMenuOpen: false,
      };
    },
    methods: {
      toggleMobileMenu() {
        this.isMobileMenuOpen = !this.isMobileMenuOpen;
      },
      closeMobileMenu() {
        this.isMobileMenuOpen = false;
      },
    },
  };
  </script>
  
  <style scoped>

.navbar {
    position: sticky;
    top: 0;
    z-index: 1000;
}

.nav-link {
    color: #2b3425;
    font-size: large;
    
}

.nav-link:hover {
    text-decoration: underline;
}

.router-link-exact-active {
    opacity: 1;
    text-decoration: underline;
    /*padding: 5px;*/
}
  </style>
  